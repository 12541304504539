<template>
<div>
    <div class="header ma-0">
        <!-- <login-title class="pt-10"></login-title> -->
        <div class="text-center">
        <h1 class="hTitle">MGM<span class="ml-1 primary--text">增粉模組</span></h1>
        <h3 class="mt-5">輕量化工具! 免費試用14天</h3>
        <h3 class="subTitle mt-2">快速建立你的好友募集活動</h3>
        </div>
        <div class="text-center">
            <v-btn color="primary" class="mt-8" width="240" height="50" elevation="0" @click="login" rounded>立即登入</v-btn>
            <h3 class="subTitle mt-15">想了解實際模組使用情況?</h3>
            <a href="https://lin.ee/tZKjjke" target="_blank"><v-btn color="primary" class="mt-2" width="240" height="50" elevation="0" rounded outlined style="background:#fff;">加入體驗帳號</v-btn></a>
        </div>
    </div>
    <v-container style="max-width:960px;" class="ma-auto">
        <!-- <h2>輕量化工具</h2> -->
        <div class="mt-120 pb-5">
            <h2 class="mb-5">模組特色</h2>
            <v-carousel
                cycle
                :show-arrows="false"
                hide-delimiter-background
                show-arrows-on-hover
                class="carousel mb-5"
                height="525"
            >
                <v-carousel-item
                    v-for="item in imgs"
                    :src="item"
                    :key="item"
                >
                </v-carousel-item>
            </v-carousel>
            <v-row wrap class="mt-2">
                <div class="feature">
                <div class="num">01</div>
                <div class="content">
                    <h3>LINE POINTS獎勵</h3>
                    <p>使用LINE POINTS做為邀請獎勵，只要完成邀請條件即可獲得點數，獲得後立即使用</p>
                </div>
                </div>
                <div class="feature">
                    <div class="num">02</div>
                    <div class="content">
                        <h3>簡單好設定</h3>
                        <p>僅須設定分享內容與邀請條件即可完成邀請活動之設定</p>
                    </div>
                </div>
                <div class="feature">
                    <div class="num">03</div>
                    <div class="content">
                        <h3>成果不漏接</h3>
                        <p>邀請排行榜詳細列出所有參與邀請活動之好友，並記錄邀請數據</p>
                    </div>
                </div>
            </v-row>
            
        </div>
        <div class="slogon outline mt-120" >
            <h2 class="mb-5">啟用教學</h2>
            <p style="max-width:500px" class="ma-auto text-center">該如何啟用荔枝Mini的MGM增粉模組呢？</p>
            <p style="max-width:500px" class="ma-auto text-center">我們準備了服務啟用教學手冊，歡迎大家下載，若設定上碰到任何問題可以透過網頁右下角「幫助」加入荔枝好推LINE官方帳號一對一聊天詢問喔！(好推OA:@585cvzsv)
            </p>
            <v-btn color="primary" width="200" height="50" class="mt-8" outlined elevation="0" @click="goToTutorial" rounded>下載教學</v-btn>
        </div>
        <h2 class="mb-5 mt-120">常見問答</h2>
        <v-expansion-panels accordion>
            <v-expansion-panel
                v-for="(question, i) in questions"
                :key="i"
            >
                <v-expansion-panel-header class="font-weight-medium text-subtitle-1"><p class="grey--text text--darken-4">{{question.ask}}</p></v-expansion-panel-header>
                <v-expansion-panel-content>
                <p> {{question.ans}}</p>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <div class="slogon solo footerCTA mt-120 mb-15">
            <v-row align="end">
                <v-col class="flex-grow-0 flex-shrink-0">
                    <h2 class="primary--text text-left nowrap">荔枝Mini</h2>
                    <h1 class="nowrap">MGM增粉模組</h1>
                </v-col>
                <v-col class="flex-shrink-0 flex-grow-1">
                    <p>$880/月(未稅)</p>
                    <!-- <p class="nowrap pb-1 red--text">夏日優惠方案買3個月送500點LINE POINTS</p> -->
                </v-col>
                <v-col class="flex-grow-0 flex-shrink-0">
                    <v-btn color="primary" class="mb-4" width="240" height="50" elevation="0" @click="login" rounded>立即登入</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-container>
</div>

</template>

<script>
// import LoginTitle from '../components/LoginTitle.vue'
import { mapActions } from 'vuex'
export default {
    name: "Login",
    // components: { LoginTitle },
    data () {
        return {
            imgs: [
            // 'img/market_place/mp00.png',
            'img/market_place/mp01.png',
            'img/market_place/mp02.png',
            'img/market_place/mp03.png',
            'img/market_place/mp04.png',
            ],
            questions:[
                {
                    ask: '◇ 與模組完成綁定後，會對我的官方帳號造成什麼影響嗎？',
                    ans: '與荔枝Mini綁定後，官方帳號的設定不會有改變，僅有當好友在官方帳號內講出「選單」二字時，會叫出MGM活動選單。'
                },
                {
                    ask: '◇ 如何購買LINE POINTS？',
                    ans: '點數有5、10、30、50四種可以選擇；費用為1點1.5元，如有購買需求，請透過網頁右下方『幫助』按鈕，與客服聯繫。'
                },
                {
                    ask: '◇ 購買的LINE POINTS何時會匯入到我的荔枝Mini帳號？',
                    ans: '確認收到款項後，約1~2個工作日匯入點數至您模組連結的荔枝Mini帳號中，一般會在一天內完成匯入。'
                },
            ]
        }
    },
    methods: {
        ...mapActions(['getLoginUrl']),
        login(){
            this.$store.commit('setIsloading',true)
            this.getLoginUrl()
        },
        goToTutorial(){
            window.open('https://drive.google.com/file/d/1LiyP6HNn4B7qMqOXYdRrLL_-7VtvlgH6/view','_blank').focus()
        }
    },
    mounted () {
        this.$gtag.event('conversion', {'send_to': 'AW-971348670/3s9VCJnLhIkDEL61ls8D'});
    },
    
};
</script>
<style lang="scss">
.carousel .v-carousel__controls {
    background-color: rgba(0, 0, 0, 0.02) !important;
    button{
        padding: 16px !important;
        i{
            font-size: 12px !important;
        }
        &.v-btn--active{
            i{
                opacity: 1;
            }
        }
    }
}
</style>
<style scoped lang="scss">
h2{
    text-align: center;
    font-size: 32px;
}
h3{
    font-size: 22px;
    font-weight: 500;
}
p{
    margin: 0 !important;
    color:#555;
    line-height: 28px;
    letter-spacing: .7px;
}
.header{
    background-color: #f3fef5;
    // height: 300px;
    width: 100%;
    padding: 100px 0;
}
.hTitle{
    letter-spacing: 1px;
    font-size: 48px;
}
.hTitle span{
    color:#555;
    letter-spacing: 2px;
    font-size: 42px;
}
.subTitle{
    letter-spacing: 2px;
    color: #555;
    font-size: 16px;
}

.features{
    display: flex;
    justify-content: space-between;
}
.feature{
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 32px;
    margin: 16px auto;
    max-width: 285px;
    min-height: 250px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    .content{
        margin-top: 12px;
        h3{
            text-align: center;
            margin-bottom: 4px;
        }
    }
    .num{
        background-color: #88d392;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin: 0 auto;
        margin-bottom: 16px;
    }
}
.slogon{
    &.outline{
        border: 1px solid #ddd;
    }
    &.solo{
        background-color: #f3fef5;
    }
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 5px;
    text-align: center;
    padding: 32px;
    &.footerCTA{
        text-align: left;
    }
}
</style>

